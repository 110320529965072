import * as React from 'react';

// Joy import
import {CssVarsProvider, useColorScheme} from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';

// Icons import
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import MenuIcon from '@mui/icons-material/Menu';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import BookRoundedIcon from '@mui/icons-material/BookRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import AddIcon from '@mui/icons-material/Add';
import teamTheme from './theme';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PaidIcon from '@mui/icons-material/Paid';
// Component imports
import Main from './components/Main';
import Menu from './components/Menu';
import Layout from './components/Layout';
import useToken from "./components/useToken";

import Login from './pages/login/Login';
import {useEffect, useState} from "react";

import { useLocation } from 'react-router-dom';

import InventoryIcon from '@mui/icons-material/Inventory';
import SellIcon from '@mui/icons-material/Sell';

function ColorSchemeToggle() {
    const {mode, setMode} = useColorScheme();
    const [mounted, setMounted] = React.useState(false);
    React.useEffect(() => {
        setMounted(true);
    }, []);
    if (!mounted) {
        return <IconButton size="sm" variant="outlined" color="primary"/>;
    }
    return (
        <IconButton
            id="toggle-mode"
            size="sm"
            variant="outlined"
            color="primary"
            onClick={() => {
                if (mode === 'light') {
                    setMode('dark');
                } else {
                    setMode('light');
                }
            }}
        >
            {mode === 'light' ? <DarkModeRoundedIcon/> : <LightModeRoundedIcon/>}
        </IconButton>
    );
}


function TeamNav() {

    let [colorSchede, setColorSchede] = React.useState('neutral');
    let [colorAutori, setColorAutori] = React.useState('neutral');
    let [colorOpere, setColorOpere] = React.useState('neutral');
    let [colorGruppi, setColorGruppi] = React.useState('neutral');
    let [colorLocalizzazione, setColorLocalizzazione] = React.useState('neutral');



    switch (window.location.pathname) {
       /* case '/schede':
            setColorSchede('primary');
            setColorAutori('neutral');
            setColorOpere('neutral');
            setColorGruppi('neutral');
            setColorLocalizzazione('neutral');
            break;
        case '/autori':
            setColorSchede('neutral');
            setColorAutori('primary');
            setColorOpere('neutral');
            setColorGruppi('neutral');
            setColorLocalizzazione('neutral');
            break;
        case '/opere':
            setColorSchede('neutral');
            setColorAutori('neutral');
            setColorOpere('primary');
            setColorGruppi('neutral');
            setColorLocalizzazione('neutral');
            break;
*/
    }




    // @ts-ignore
    return (
        <List size="sm" sx={{'--List-item-radius': '8px', '--List-gap': '4px'}}>
            <ListItem nested>
                <ListSubheader>
                    Browse
                    <IconButton
                        size="sm"
                        variant="plain"
                        color="primary"
                        sx={{'--IconButton-size': '24px', ml: 'auto'}}
                    >
                        <KeyboardArrowDownRoundedIcon fontSize="small" />
                    </IconButton>
                </ListSubheader>
                <List
                    aria-labelledby="nav-list-browse"
                    sx={{
                        '& .JoyListItemButton-root': {p: '8px'},
                    }}
                >

                    <ListItem>
                        <ListItemButton component="a" href="/schede"  >

                            <ListItemDecorator >
                                <AssignmentIndRoundedIcon fontSize="small"/>
                            </ListItemDecorator>
                            <ListItemContent>Schede</ListItemContent>
                            <IconButton
                                size="sm"
                                variant="outlined"
                                color="neutral"
                                component="a"
                                href="/scheda/aggiungi"
                            >
                                <AddIcon/>
                            </IconButton>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton component="a" href="/autori">
                            <ListItemDecorator sx={{color: 'neutral.500'}}>
                                <ColorLensIcon fontSize="small"/>
                            </ListItemDecorator>
                            <ListItemContent>Autori</ListItemContent>
                            <IconButton
                                size="sm"
                                variant="outlined"
                                color="neutral"
                                component="a"
                                href="/autori/aggiungi"
                            >
                                <AddIcon/>
                            </IconButton>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton component="a" href="/opere">
                            <ListItemDecorator sx={{color: 'neutral.500'}}>
                                <PeopleRoundedIcon fontSize="small"/>
                            </ListItemDecorator>
                            <ListItemContent>Opere</ListItemContent>
                            <IconButton
                                size="sm"
                                variant="outlined"
                                color="neutral"
                                component="a"
                                href="/opere/aggiungi"
                            >
                                <AddIcon/>
                            </IconButton>
                        </ListItemButton>
                    </ListItem>

                    <ListItem>
                        <ListItemButton component="a" href="/localizzazioni">
                            <ListItemDecorator sx={{color: 'neutral.500'}}>
                                <LocationOnIcon fontSize="small"/>
                            </ListItemDecorator>
                            <ListItemContent>Localizzazioni</ListItemContent>
                            <IconButton
                                size="sm"
                                variant="outlined"
                                color="neutral"
                                component="a"
                                href="/localizzazione/aggiungi"
                            >
                                <AddIcon/>
                            </IconButton>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton component="a" href="/gruppi">
                            <ListItemDecorator sx={{color: 'neutral.500'}}>
                                <LocationCityIcon fontSize="small"/>
                            </ListItemDecorator>
                            <ListItemContent>Gruppi</ListItemContent>
                            <IconButton
                                size="sm"
                                variant="outlined"
                                color="neutral"
                                component="a"
                                href="/gruppi/aggiungi"
                            >
                                <AddIcon/>
                            </IconButton>
                        </ListItemButton>
                    </ListItem>

                    <ListItem>
                        <ListItemButton component="a" href="/fatture/vendita">
                            <ListItemDecorator sx={{color: 'neutral.500'}}>
                                <SellIcon fontSize="small"/>
                            </ListItemDecorator>
                            <ListItemContent>Vendita</ListItemContent>
                            <IconButton
                                size="sm"
                                variant="outlined"
                                color="neutral"
                                component="a"
                                href="/fatture/vendita/add"
                            >
                                <AddIcon/>
                            </IconButton>
                        </ListItemButton>
                    </ListItem>

                    <ListItem>
                        <ListItemButton component="a" href="/fatture/acquisto">
                            <ListItemDecorator sx={{color: 'neutral.500'}}>
                                <InventoryIcon fontSize="small"/>
                            </ListItemDecorator>
                            <ListItemContent>Acquisto</ListItemContent>
                            <IconButton
                                size="sm"
                                variant="outlined"
                                color="neutral"
                                component="a"
                                href="/fatture/acquisto/add"
                            >
                                <AddIcon/>
                            </IconButton>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton component="a" href="/reports">
                            <ListItemDecorator sx={{color: 'neutral.500'}}>
                                <PaidIcon fontSize="small"/>
                            </ListItemDecorator>
                            <ListItemContent>Statistiche</ListItemContent>
                        </ListItemButton>
                    </ListItem>
                </List>
            </ListItem>
        </List>
    );
}

const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    // @ts-ignore
    return JSON.parse(tokenString);
};


export default function App() {

    const { token, setToken } = useToken();

    const [tmp, setTmp] = React.useState();
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    if (!getToken()) {
        // @ts-ignore
        return <Login setToken={setToken} setTmp={setTmp}/>
    }

    function handleLogout () {
        sessionStorage.removeItem('token');
        setToken('');
    }

    return (
        <CssVarsProvider disableTransitionOnChange theme={teamTheme}>
            <CssBaseline/>
            {drawerOpen && (
                <Layout.SideDrawer onClose={() => setDrawerOpen(false)}>
                    <TeamNav/>
                </Layout.SideDrawer>
            )}
            <Layout.Root
                sx={{
                    ...(drawerOpen && {
                        height: '100vh',
                        overflow: 'hidden',
                    }),
                }}
            >
                <Layout.Header>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 1.5,
                        }}
                    >
                        <IconButton
                            variant="outlined"
                            size="sm"
                            onClick={() => setDrawerOpen(true)}
                            sx={{display: {sm: 'none'}}}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <img src="/palma.svg" width="25" />
                        <Typography component="h1" fontWeight="xl">
                            Palma Arte
                        </Typography>
                    </Box>

                    <Box sx={{display: 'flex', flexDirection: 'row', gap: 1.5}}>

                        <IconButton
                            size="sm"
                            variant="outlined"
                            color="primary"
                            component="a"
                            href="/scheda/aggiungi"
                        >
                            <AddIcon/>
                        </IconButton>


                        <ColorSchemeToggle/>
                        <IconButton
                            size="sm"
                            variant="outlined"
                            color="primary"
                            component="a"
                            onClick={handleLogout}
                        >
                            <LogoutIcon/>
                        </IconButton>
                    </Box>
                </Layout.Header>
                <Layout.SideNav>
                    <TeamNav/>
                </Layout.SideNav>
                <Layout.Main>
                    <Main/>
                </Layout.Main>
            </Layout.Root>
        </CssVarsProvider>
    );
}
