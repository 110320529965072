import '../App.css';
import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router'

import {CssVarsProvider} from '@mui/joy/styles';
import Autocomplete from '@mui/joy/Autocomplete';
import Input from '@mui/joy/Input';
import Textarea from '@mui/joy/Textarea';
import Sheet from '@mui/joy/Sheet';
import Grid from '@mui/joy/Grid';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Button from '@mui/joy/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Stack from '@mui/joy/Stack';

const VenditaAdd = () => {

    const token = sessionStorage.getItem('token').replace(/^"(.*)"$/, '$1');
    let endpoint = 'https://gestionale.palmaarte.webdistrict.it/api/web';

    let [autore, setAutore] = useState();
    let [autori, setAutori] = useState();
    let [cliente, setCliente] = useState();
    let [data, setData] = useState();
    let [misura, setMisura] = useState();
    let [prezzo, setPrezzo] = useState();
    let [num, setNum] = useState();
    let [regime, setRegime] = useState();
    let [note, setNote] = useState();
    let [fatt, setFatt] = useState();

    let [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    function handleAutore(event, newValue) {
        const selectedAutoreId = newValue ? newValue.id : null;
        // Imposta autore_id con l'ID estratto
        setAutore(selectedAutoreId);
    }

    function handleFatt(event) {
        setFatt(event.target.value);
    }


    function handleCliente(event) {
        setCliente(event.target.value);
    }

    function handleData(event) {
        setData(event.target.value);
    }

    function handleMisura(event) {
        setMisura(event.target.value);
    }

    function handlePrezzo(event) {
        setPrezzo(event.target.value);
    }

    function handleNum(event) {
        setNum(event.target.value);
    }

    function handleRegime(event) {
        setRegime(event.target.value)
    }

    function handleNote(event) {
        setNote(event.target.value);
    }

    function handleSend(event) {
        event.preventDefault();
        setLoading(false);

        var details = {
            'autore_id' : autore,
            'nome_cliente' : cliente,
            'fatt' : fatt,
            'data' : (data) ? data : '',
            'misura' : misura,
            'prezzo' : prezzo,
            'num' : num,
            'regime' : regime,
            'note' : (note) ? note : '',
            'tipo' : 1
        }

        var formBody = []

        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }

        formBody = formBody.join("&");

        fetch(`${endpoint}/fattura`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${token}`
            },
            body: formBody
        }).then((response) => response.json())
            .then(res => {
                if (res.errore) {
                    toast.error(res.errore, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 4000,
                        theme: "light"
                    });
                } else {
                    navigate(`/fatture/vendita/edit/${res.id}?new=1`)
                }

            }).catch((err) => {
            toast.error(err.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 4000,
                theme: "light"
            });
            setLoading(false);
        })
    }

    useEffect(() => {
        fetch(`${endpoint}/autori?fields=id,descrizione`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                "Authorization": `Bearer ${token}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.items) { // Verifica se data e data.items sono definiti
                    let namesArr = [];
                    data.items.forEach((repo) => {
                        var valueToPush = {};
                        valueToPush.id = +repo.id;
                        valueToPush.label = repo.descrizione;
                        namesArr.push(valueToPush);
                    });
                    setAutori(namesArr);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [0, refresh]);


    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    function unique(item, index, array) {
        return array.indexOf(item) == index;
    }

    function handleNotification(event) {
        setOpen(!open);
    }


    return (
        <React.Fragment>
            <ToastContainer style={{width: "400px"}}/>

            <CssVarsProvider>
                <Sheet
                    key="1"
                    component="li"
                    variant="outlined"
                    sx={{
                        borderRadius: 'sm',
                        p: 2,
                        listStyle: 'none',
                    }}
                >
                    <Grid item xs={10}  marginBottom={2}>
                        <Button onClick={goBack} variant="outlined" startIcon={<ArrowBackIosIcon/>}>
                            Indietro
                        </Button>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormLabel>N. Fatt. (*)</FormLabel>
                            <Input fullWidth required id="fatt" label="N. Fatt." name="fatt" variant="outlined"
                                   onChange={handleFatt}/>
                        </Grid>
                        <Grid item xs={12} md={6}>

                            <FormControl id="multiple-limit-tags">
                                <FormLabel>Autori (*)</FormLabel>
                                <Stack direction="row" xs={10}>
                                    <Autocomplete
                                        sx={{flexGrow: 1}}
                                        name="autori"
                                        id="autori"
                                        placeholder="Autori"
                                        limitTags={1}
                                        options={autori || []}

                                        onChange={handleAutore} // Passa la funzione di gestione degli eventi

                                    />




                                </Stack>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormLabel>Cliente (*)</FormLabel>
                            <Input fullWidth required id="cliente" label="Cliente" name="cliente" variant="outlined"

                                   onChange={handleCliente}/>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormLabel>Data (*)</FormLabel>
                            <Input fullWidth required type="date" id="data" label="Data" name="data" variant="outlined"

                                   onChange={handleData}/>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormLabel>Misura</FormLabel>
                            <Input fullWidth id="misura" label="Misura" name="misura" variant="outlined"

                                   onChange={handleMisura}/>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormLabel>Prezzo (*)</FormLabel>
                            <Input fullWidth required id="prezzo" label="Prezzo" name="prezzo" variant="outlined"

                                   onChange={handlePrezzo}/>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormLabel>Regime</FormLabel>
                            <Input fullWidth required id="regime" label="Regime" name="regime" variant="outlined"

                                   onChange={handleRegime}/>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormLabel>Num (*)</FormLabel>
                            <Input fullWidth required id="num" label="Num" name="num" variant="outlined"

                                   onChange={handleNum}/>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormLabel>Note</FormLabel>
                            <Textarea fullWidth minRows={4} variant="outlined"
                                      name="note"
                                      onChange={handleNote}/>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Button variant="solid" type="submit" loading={loading} onClick={handleSend}>
                                Salva
                            </Button>
                        </Grid>
                        <p>(*) campi obbligatori</p>
                    </Grid>

                </Sheet>
            </CssVarsProvider>
        </React.Fragment>
    );
};

export default VenditaAdd;